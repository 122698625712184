<template>
  <b-card title="Firebase Analitcs">
    <h3>Dados para acesso ao Firebase Analytcs</h3>
    <h6>usuário: administrativo@visinonni.com.br</h6>
    <h6>Senha: visinonni*2021</h6>
    <a href="https://console.firebase.google.com/project/visinonni-95661/analytics/app/android:br.com.visinonni/overview" target="_blank"> Abrir Firebase Analytcs</a>
  </b-card>
</template> 
<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
}
</script>